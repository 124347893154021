import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  user_externalapplication_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/externalapplication/get?' + query;
    } else {
      route = 'v1/user/externalapplication/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_externalapplication_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/externalapplication/list?' + query;
    } else {
      route = 'v1/user/externalapplication/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_externalapplication_record (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/externalapplication/record?' + query;
    } else {
      route = 'v1/user/externalapplication/record';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
};
